import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, FormControlLabel, FormLabel, IconButton, InputAdornment, InputLabel, LinearProgress, OutlinedInput, Radio, RadioGroup, Skeleton, Snackbar, Stack, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './userCard.sass'
import Modal from '@mui/material/Modal';

import CloseIcon from '@mui/icons-material/Close';

import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import demo1 from '../../assets/images/demo.jpg'
import demo2 from '../../assets/images/demo2.jpg'
import demo3 from '../../assets/images/demo3.jpg'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CheckIcon from '@mui/icons-material/Check';

import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

const steps = ['Biometria', 'Frente do documento', 'Verso do documento', 'Conclusão'];


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { apiEvent, apiJaiminho, apiUser, refreshToken } from '../ApiCall';
import UserBiometryImage from './UserBiometryImage';
import { GridLoadingOverlay } from '@mui/x-data-grid';

export interface UserCardProps {
  userData: any
  handleRefresh: (response: any) => void
  handleCloseBox: (response: any) => void
}

const dateFormatter = (dateTime:string)=>{

  const options:any = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const oldDate = new Date(dateTime)
  return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
}

const UserEventCart = ({userData, handleRefresh, handleCloseBox}:UserCardProps) => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;

  const { focused } = useFormControl() || {};
  

  const [ticketSyncInfo, setTicketSyncInfo] = useState<any>(null)
  const getTicketInfo  = async (userDocument: string) => {
    const responseApi = await apiEvent.post('/sync/user/status', {
      searchTerm: userDocument
    })

    if (responseApi.data && responseApi.data.length > 0) {
      setTicketSyncInfo(responseApi.data)
    }else{
      setTicketSyncInfo(null)
    }
  }


  useEffect(() => {
    getTicketInfo(userData.document)
  }, [])


  return (
    <Card className="user-card" variant="outlined">
      <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      {userData.name}
      </Typography>
      <Box className="user-card-header-icons" sx={{ display: { xs: 'none', md: 'block' }}}>
        <i onClick={() => {handleCloseBox(true)}}>
        <CloseIcon />
        </i>
      </Box>
      </Box>
      <Box>
<ul className="user-card-content-details">
        <li>{userData.name}</li>
        <li>{userData.document}</li>
        <li>{userData.email}</li>
        <li><strong>ingresso:</strong> {ticketSyncInfo? ticketSyncInfo[0]?.accessCode : ''}</li>
        </ul>
</Box>
      <CardContent>

        <Grid container spacing={2}>


<Grid sx={{xs:3, md:12}}>
  <div className="user-card-image-wrap solo-view">
    {/* <i><SearchIcon /></i> */}
    {userData.userAvatar ? (
      <img src={`data:image/png;base64,${userData.userAvatar}`} alt="" />
    ):(
    <ImageNotSupportedOutlinedIcon/>
    )}

  </div>
</Grid>
<Grid size={{xs:12, md:3}}>
<div className="user-card-image-wrap solo-view">
    {/* <i><SearchIcon /></i> */}
    {(userData.biometric && userData.docFront) ? (
      <img src={`data:image/png;base64,${userData.docFront}`} alt="" />
    ):(
    <ImageNotSupportedOutlinedIcon/>
    )}
  </div>
</Grid>
<Grid size={{xs:12,  md:12, xl: 6}}>
    {ticketSyncInfo ? (
      <ul className="accordion-user-sync-list">
      {ticketSyncInfo.map((devices:any, index:any) => {
        if(devices.deviceIP !== 'xxx'){
          return (
            <li key={index} className={devices.isFaceCreated === 'notStarted' ? 'offline':'done'}>
              {devices.deviceIP}
          </li>
          )
        }
      })}
      </ul>
    ):(
      <p>Nenhum ingresso encontrado</p>
    )}
</Grid>
</Grid>
      </CardContent>

    </Card>
  )
};

export default UserEventCart;