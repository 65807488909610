import axios from 'axios';

const getSessionData = () => {
  const sessionItem = window.sessionStorage.getItem('user-key');
  return sessionItem ? JSON.parse(sessionItem) : { user_key: null, tenant_id: null };
};

const createApiInstance = (baseURL: string, additionalHeaders = {}) => {
  const instance = axios.create({
    baseURL,
  });
  instance.interceptors.request.use((config) => {
    const { user_key, tenant_id } = getSessionData();
    config.headers['x-api-key'] = user_key;
    config.headers['x-tenant-id'] = tenant_id;
    Object.assign(config.headers, additionalHeaders);
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        window.dispatchEvent(new Event('unauthorized'));
      }

      return Promise.reject(error);
    }
  );
  return instance;
};

const apiCompany = createApiInstance('https://service-company-563791290358.southamerica-east1.run.app', { 'x-api-version': 'v2' });

const apiAuth = createApiInstance('https://auth.bepass.app', { 'x-api-version': 'v2' });
const apiJaiminho = createApiInstance('https://service-jaiminho-corinthians-563791290358.southamerica-east1.run.app');
const deviceApi = createApiInstance('http://192.168.0.56:3000');
const apiUser = createApiInstance('https://users.bepass.app');

let apiEvent: any;

const hml_vars = new URLSearchParams(window.location.search);
const _temp_cmp = sessionStorage.getItem('company_slug') || null;
let _homol_company_validation = hml_vars.get('cmp') || 'na';

if (_homol_company_validation === 'palmeiras' || _temp_cmp === 'palmeiras') {
  apiEvent = createApiInstance('https://new-dash---service-event-palmeiras-74hf2txj6q-rj.a.run.app');
} else {
  apiEvent = createApiInstance('https://events.bepass.app');
}

const refreshToken = async () => {
  const refreshToken = sessionStorage.getItem('refreshToken');

  try {
    let refreshAccess: any = await apiAuth.post(`/auth/refresh-token`, {}, {
      headers: {
        'x-refresh-token': refreshToken,
      }
    });

    if (refreshAccess.data && refreshAccess.data.refresh_token) {
      refreshAccess = refreshAccess.data;
      sessionStorage.setItem('user-key', JSON.stringify({ user_key: refreshAccess?.token, tenant_id: getSessionData().tenant_id }));
      sessionStorage.setItem('refreshToken', refreshAccess?.refresh_token);
      return true;
    }
  } catch (e) {
    console.log('cant make it hon :(');
  }

  return false;
};

export { apiCompany, apiEvent, apiUser, apiJaiminho, deviceApi, refreshToken };
