import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, FormControlLabel, FormLabel, IconButton, InputAdornment, InputLabel, LinearProgress, OutlinedInput, Radio, RadioGroup, Skeleton, Snackbar, Stack, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './userCard.sass'
import Modal from '@mui/material/Modal';

import CloseIcon from '@mui/icons-material/Close';

import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import demo1 from '../../assets/images/demo.jpg'
import demo2 from '../../assets/images/demo2.jpg'
import demo3 from '../../assets/images/demo3.jpg'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CheckIcon from '@mui/icons-material/Check';

import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

const steps = ['Biometria', 'Frente do documento', 'Verso do documento', 'Conclusão'];


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { apiJaiminho, apiUser, refreshToken } from '../ApiCall';
import UserBiometryImage from './UserBiometryImage';
import { GridLoadingOverlay } from '@mui/x-data-grid';

export interface UserCardProps {
  userData: any
  handleRefresh: (response: any) => void
  handleCloseBox: (response: any) => void
}

const dateFormatter = (dateTime:string)=>{

  const options:any = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const oldDate = new Date(dateTime)
  return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
}

const UserCard = ({userData, handleRefresh, handleCloseBox}:UserCardProps) => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;

  const { focused } = useFormControl() || {};
  

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const [biometryUpdate, setBiometryUpdate] = useState<any>({
    face: null,
    faceImage: null,
    documentFront: null,
    documentFrontImage: null,
    documentBack: null,
    documentBackImage: null,
    token: null
  });

  const [fileUploaderStatus, setFileUploaderStatus] = useState<any>({
    fileName: 'arquivo',
    filePercent: 0,
    uploading: false,
  });

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };



  const getImageFromPath = async (imagePath:any) => {

    try{
      const response = await apiUser.post('/biometry/getimageByPath', 
        {
          path: imagePath
        }
      );
      return response.data?.image || 'na'
  
    }catch (error) {
    console.error(error);
    }

  }
  const getAllImages = async() => {

    const tempBiometryData = currentItems;

    for(const item of tempBiometryData){

  // documentBack: photo.documentBack,
  // documentFront: photo.documentFront,
  // facialPath: photo.resizedImageUrl,
  // base64ResizedPhoto: null,
  // base64DocumentFront: null,
  // base64DocumentBack: null,

      if(item.documentBack){
        item.base64DocumentBack = await getImageFromPath(item.documentBack);
      }
      if(item.documentFront){
        item.base64DocumentFront = await getImageFromPath(item.documentFront);
      }
      if(item.facialPath){
        item.base64ResizedPhoto = await getImageFromPath(item.facialPath);
      }
    }
    console.log('- > >>  ')
    console.log(tempBiometryData)

    setCurrentItems(tempBiometryData)

    setBiometryUpdate({
      face: tempBiometryData[0].facialPath,
      faceImage: tempBiometryData[0].base64ResizedPhoto,
      documentFront: tempBiometryData[0].documentFront,
      documentFrontImage: tempBiometryData[0].base64DocumentFront,
      documentBack: tempBiometryData[0].documentBack,
      documentBackImage: tempBiometryData[0].base64DocumentBack,
      token: tempBiometryData[0].token
    });

    setTimeout(() => {
      handleBackgropLoaderClose()
      setOpen(true);
    }, 1000);
  }

  const [backgropLoader, setBackgropLoader] = React.useState(false);
  const handleOpenBackgropLoader = () =>  setBackgropLoader(true);
  const handleBackgropLoaderClose = () => setBackgropLoader(false);

  const [userRoleId, setUserRoleId] = useState(sessionStorage.getItem('role_id') || '0')
  

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    handleOpenBackgropLoader()
    getAllImages()
  }
  const handleClose = () => setOpen(false);


  const [onboardingLinkText, setOnboardinLinkText] = useState('Link Onboarding')
  const getOnboardingLink = async (token:any) => {

    const shortLink = await apiJaiminho.post('/shorter', 
      {
        operator: sessionStorage.getItem('token') || 'na',
        token: token,
        company: sessionStorage.getItem('company_id') || 'na',
        link: `https://${sessionStorage.getItem('company_slug')}.bepass.com.br?token=${token}`,
      }
    );

    if(shortLink?.data){

      try{
        if (document.hasFocus()) {
          navigator.clipboard.writeText(`${shortLink.data.link}`);
        }
      }catch(e){
        console.log('cant find this freaking window!')
      }

      setOnboardinLinkText('Link Copiado!')
     return setTimeout(() => {
        setOnboardinLinkText('Link Onboarding')
      }, 2000);
    }

    alert('Desculpe, não conseguimos gerar o link :(')


    return 
  }


  const [success, setSuccess] = React.useState(false);

  const sendSelection = async () => {
    console.log(biometryUpdate)
    handleComplete();

    const _temp_operator = sessionStorage.getItem('token')
    try {
      await apiUser.post('/biometry/internal/forceApproval', 
        {
          selfie: biometryUpdate.face,
          docFront: biometryUpdate.documentFront,
          docBack: biometryUpdate.documentBack,
          operator: _temp_operator,
          token: biometryUpdate.token
        }
      );

      setSuccess(true)

      setTimeout(() => {
        
        setActiveStep(0);
        setCompleted({});
        handleClose();

        handleRefresh(userData.document)

      }, 3000);

    } catch (error) {
      console.error(error);
    }
 
  }

  const [biometryRemovedInfo, setBiometryRemovedInfo] = useState(false);

  const [tokenIntegration, setTokenIntegration] = useState(false);

  let _remove_biometry_times = 0
  const removeBiometry = async (token: any, user_document: any) => {
  const _temp_operator = sessionStorage.getItem('token')
    try {
      const response = await apiUser.post('/biometry/document/invalidate', 
        {
          token,
          operator: _temp_operator,
        }
      );
      console.log(response.data);

      _remove_biometry_times = 0
      setBiometryRemovedInfo(true)

      setTimeout(() => {
        handleRefresh(userData.document)
        setBiometryRemovedInfo(false)
      }, 3000);
    
    
    } catch (error) {
      const _temp_can_refresh = await refreshToken()
      if(_remove_biometry_times > 2){
        console.error(error);
      }else{
        _remove_biometry_times++
        if(_temp_can_refresh){
          removeBiometry(token,user_document)
        }else{
          const _temp_company = sessionStorage.getItem('selected_cmp') || ''
          sessionStorage.clear()
          if(_temp_company){
            sessionStorage.setItem('selected_cmp', _temp_company)
          }
        }
      }
    }
}


const [currentItems, setCurrentItems] = useState<any>();

const getUsersBiometriesList = async() => {

try {
  const response = await apiUser.post('/biometry/find/user/biometrics', 
    {
      userSearchType: 'document',
      userId: userData.document,
      page: 1,
      pageSize: 20
    }
  );
  console.log(response.data);
  setCurrentItems(response.data.biometrics)

} catch (error) {
  console.error(error);
}

}


let pacoca = 0
useEffect(() => {
  if(pacoca === 0){
    ++pacoca
    getUsersBiometriesList()
  }
}, [userData])


const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log(event.target.name)

  setFileUploaderStatus({
    ...fileUploaderStatus,
    uploading: true
  })



  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];
    const reader = new FileReader();



    const timer = setInterval(() => {
      setFileUploaderStatus((oldProgress:any) => {
        if (oldProgress.filePercent > 80) {
          clearInterval(timer);
        }
        const diff = Math.random() * 10;
        return {
          ...oldProgress,
        uploading: true,
        filePercent: Math.min(oldProgress.filePercent + diff, 100)
      }
      });
    }, 500);

    
    reader.onloadend = async () => {
      const base64String = reader.result as string;
      console.log(biometryUpdate.token)
      console.log(base64String.split(',')[0],)
      console.log(base64String.split(',')[1],)


      await createDocumentLine({
        token: biometryUpdate.token,
        side: event.target.name,
        image: base64String.split(',')[1]
      })

      clearInterval(timer);

      setFileUploaderStatus({
        ...fileUploaderStatus,
        filePercent: 100,
        uploading: true,
      })

      setTimeout(() => {
        setFileUploaderStatus({
          fileName: 'arquivo',
          filePercent: 0,
          uploading: false,
        })
        handleComplete()
      }, 3000);
    };

    reader.readAsDataURL(file);
  }
};

const createDocumentLine = async (imageData: any) => {
  try {
    const imagePath = await apiUser.post('/biometry/create/document/raw', 
      {
        originToken: imageData.token,
        side: imageData.side,
        documentType: 'custom-uploader',
        imageBase64: imageData.image
      }
    );
    const tempArray = currentItems

    if(imagePath.data && imagePath.data.fileData?.document_front){

      tempArray.push({
        base64DocumentFront: imageData.image,
        documentFront: imagePath.data.fileData?.document_front
      })

      setBiometryUpdate({
        ...biometryUpdate,
        documentFront: imagePath.data.fileData?.document_front,
        documentFrontImage: imageData.image,
      });
    }else if(imagePath.data && imagePath.data.fileData?.document_back){

      tempArray.push({
        base64DocumentBack: imagePath.data.fileData?.document_back,
        documentBack: imageData.image,
      })

      setBiometryUpdate({
        ...biometryUpdate,
        documentBack: imagePath.data.fileData?.document_back,
        documentBackImage: imageData.image,
      });
    }

    setCurrentItems(tempArray)

    return true
  } catch (error) {
    console.error(error);
    return false
  }
}


  const forceIntegration  = async (token: string) => {
    const responseApi = await apiUser.post('/user/force-integration', {
      token
    })

    if (responseApi.data === true) {
      setTokenIntegration(true);
    }
  }




  return (
    <Card className="user-card" variant="outlined">
      <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      {userData.name}
      </Typography>
      <Box className="user-card-header-icons" sx={{ display: { xs: 'none', md: 'block' }}}>
        <i onClick={() => {handleCloseBox(true)}}>
        <CloseIcon />
        </i>
      </Box>
      </Box>
      <Box>
<ul className="user-card-content-details">
        <li>{userData.name}</li>
        <li>{userData.document}</li>
        <li>{userData.email}</li>
        <li><strong>newc-id:</strong> {userData.externalId}</li>
        </ul>
</Box>
      <CardContent>

        <Grid container spacing={2}>


<Grid sx={{xs:3, md:12}}>
  <div className="user-card-image-wrap solo-view">
    {/* <i><SearchIcon /></i> */}
    {userData.userAvatar ? (
      <img src={`data:image/png;base64,${userData.userAvatar}`} alt="" />
    ):(
    <ImageNotSupportedOutlinedIcon/>
    )}

  </div>
</Grid>
<Grid size={{xs:12, md:3}}>
<div className="user-card-image-wrap solo-view">
    {/* <i><SearchIcon /></i> */}
    {(userData.biometric && userData.docFront) ? (
      <img src={`data:image/png;base64,${userData.docFront}`} alt="" />
    ):(
    <ImageNotSupportedOutlinedIcon/>
    )}
  </div>
</Grid>
<Grid size={{xs:12, md:3}}>
<div className="user-card-image-wrap solo-view">
    {/* <i><SearchIcon /></i> */}
    {(userData.biometric && userData.docBack) ? (
      <img src={`data:image/png;base64,${userData.docBack}`} alt="" />
    ):(
      <ImageNotSupportedOutlinedIcon/>
    )}
  </div>
</Grid>
<Grid size={{xs:12, md:3}}>
  <Box className="user-card-info-action">
<Typography variant="h4" noWrap component="div">
      Status - {userData.document}
      </Typography>
      <ul className="user-card-info-timeline">
        <li className={userData.user_create ? 'done': 'waiting'}>
        <Typography variant="h6" noWrap component="div">
        <i><TaskAltOutlinedIcon /></i>
      Cadastro
      </Typography>
      <span>
      {userData.user_create ? dateFormatter(userData.user_create): '--'}
      </span>
        </li>
        <li className={userData.biometric ? 'done': 'waiting'}>
        <Typography variant="h6" noWrap component="div">
        <i><TaskAltOutlinedIcon /></i>
      Biometria
      </Typography>
      <span>
      {userData.biometric ? dateFormatter(userData.biometric_create): '--'}
      </span>
        </li>
        <li className={userData.documentValidation === 'approved' ? 'done': 'waiting'}>
        <Typography variant="h6" noWrap component="div">
        <i><TaskAltOutlinedIcon /></i>
      Documento
      </Typography>
      <span>
      {userData.documentValidation === 'approved' ? dateFormatter(userData.document_approved): '--'}
      </span>
        </li>
        <li className={userData.documentValidation === 'approved' ? 'done': 'waiting'}>
        <Typography variant="h6" noWrap component="div">
        <i><TaskAltOutlinedIcon /></i>
      Completo
      </Typography>
      <span>
      {userData.documentValidation === 'approved' ? dateFormatter(userData.document_approved): '--'}
      </span>
        </li>
      </ul>
      </Box>

      <Button className='default-button' variant="outlined"
      onClick={() => getOnboardingLink(userData.token)}> {onboardingLinkText}</Button>
    {['1','6','7','8', '2'].indexOf(userRoleId) >= 0 && (
      <Button
      sx={{ display: { xs: 'none', md: 'block' }}}
      onClick={handleOpen}
      className='default-button' variant="outlined">Aprovação Manual</Button>
    )}
    {['1','6','7', '8', '2'].indexOf(userRoleId) >= 0 && (
      <Button className='default-button' variant="outlined"
       onClick={() => {
        removeBiometry(userData.token, userData.document);
    }}
      >Refazer Biometria</Button>
    )}

{['1','6','7', '2'].indexOf(userRoleId) >= 0 && (
      <Button 
        onClick={() => forceIntegration(userData.token)}
        className='default-button'
        variant="outlined"
      >
        Forçar Integração
      </Button>
        )}
      



<Snackbar
  open={biometryRemovedInfo}
  autoHideDuration={6000}
  message="Biometria apagada"
  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
/>

<Snackbar
  open={tokenIntegration}
  autoHideDuration={6000}
  message="Integração Enviada"
  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
/>
</Grid>

</Grid>

      </CardContent>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal">

        <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Aprovação Manual
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => handleClose()}>
        <CloseIcon
        />
        </i>
      </div>
      </Box>

      <Box sx={{ width: '100%' }}>
      <Stepper className='custom-stepper' activeStep={1} alternativeLabel>
        {steps.map((label, index) => (
          <Box key={label} className={`
          custom-step
          ${activeStep === index ? 'active':''}
          ${completed[index] ? 'done':''}
          `}
          // onClick={handleComplete}
          >
              <TaskAltOutlinedIcon />
            {label}
          </Box>
          // <Step key={label}>
          //   <StepLabel>{label} {`${completed[index]}`}ss</StepLabel>
          // </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <Box className="modal-approval-result">
            {success ? (
              <Box className="icon-success">
                <CheckIcon />
                Biometria atualizada!
              </Box>
            ):(
              <CircularProgress size="3rem" />
            //   <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            //   <Box sx={{ flex: '1 1 auto' }} />
            //   <Button onClick={handleReset}>Reset</Button>
            // </Box>
            )}

            </Box>
        ) : (activeStep === 0) ? (

            <FormControl>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue={0}
    name="radio-buttons-group"
  >
    <Grid container spacing={2} className={`modal-image-carrousel ${(currentItems && currentItems.length >= 3) || 'no-wrapper-frame'}`}>
{currentItems && (
currentItems
          .filter((document:any) => document.facialPath)
          .map((document:any, index:any) => (document.base64ResizedPhoto) ? (
            <Grid key={index} size={4}>
            <div className="user-card-image-wrap">
            <label htmlFor={`face-${index}`}>
            <img
            src={`data:image/png;base64,${document.base64ResizedPhoto}`}
            className="face-image"
            />
            {/* <UserBiometryImage key={'selfie'} imagePath={document.facialPath} imageSide='face' /> */}
            </label>
          <FormControlLabel  value={index} control={<Radio 
          onChange={() => setBiometryUpdate({...biometryUpdate, face: document.facialPath, faceImage: document.base64ResizedPhoto})}
          id={`face-${index}`} />} label={<Typography variant="caption">{dateFormatter(document.documentDate)}</Typography>} />

</div>
</Grid>
        ):(<></>))
      )}
          
          </Grid>
          <Box className="user-approved-action-button">
               <Button
                 disabled={activeStep === 0}
                 onClick={handleBack}
               >
                 Voltar
               </Button>

               <Button onClick={handleComplete}>
                        Próximo
                      </Button>
             </Box>
             
          </RadioGroup>
          </FormControl>

        ) : (activeStep === 1) ? (

          <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={0}
            name="radio-buttons-group"
          >
            <Grid container spacing={2} className={`modal-image-carrousel ${(currentItems && currentItems.length >= 3) || 'no-wrapper-frame'}`}>
        {currentItems && (
        currentItems
                  .filter((document:any) => document.documentFront)
                  .map((document:any, index:any) => (document.base64DocumentFront) ? (
                    <Grid key={index} size={4}>
                    <div className="user-card-image-wrap">
                    <label htmlFor={`docfront-${index}`}>
                    <img
            src={`data:image/png;base64,${document.base64DocumentFront}`}
            className="face-image"
          />
                    {/* <UserBiometryImage key={'front'} imagePath={document.documentFront} imageSide='face' /> */}
                    </label>
                  <FormControlLabel  value={index} control={<Radio
                  onChange={() => setBiometryUpdate({...biometryUpdate, documentFront: document.documentFront,documentFrontImage: document.base64DocumentFront})}
                   id={`docfront-${index}`} />} label={<Typography variant="caption">{dateFormatter(document.documentDate)}</Typography>} />
        
        </div>
        </Grid>
                ):(<></>))
              )}
                
                           
                  </Grid>

                  {fileUploaderStatus.uploading ? (
                  <Box className="upload-file-wrapper">
                  <Box className="upload-file-caption">
                  <Typography variant="caption">
                    {fileUploaderStatus.fileName}
                    {fileUploaderStatus.filePercent === 100 ? (
                      <span>upload completo!...</span>
                    ):(
<span>subindo... {Math.round(fileUploaderStatus.filePercent)}</span>
                    )}
                    
                  </Typography>
                  <SearchIcon />
                  </Box>
                  <LinearProgress color={ (fileUploaderStatus.filePercent === 100) ? 'success':'info'} variant="determinate" value={fileUploaderStatus.filePercent} />
                </Box>
    ):(
      <label htmlFor={`docfront-uploader`} className="upload-field">
      <SearchIcon />
      <Typography variant="caption">
          Importar arquivo
        </Typography>
        <input className="controls-input"
          type="file"
          id="docfront-uploader"
          accept="image/*"
          name='document_front'
          onChange={handleImageUpload}
        /> 
    </label> 
    )}

                  
                  <Box className="user-approved-action-button">
                       <Button
                         onClick={handleBack}
                       >
                         Voltar
                       </Button>
        
                       <Button onClick={handleComplete}>
                        Próximo
                      </Button>
                     </Box>
                  </RadioGroup>
                  </FormControl>
        
        ) : (activeStep === 2) ? (
          <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={0}
            name="radio-buttons-group"
          >
            <Grid container spacing={2} className={`modal-image-carrousel ${(currentItems && currentItems.length >= 3) || 'no-wrapper-frame'}`}>
        {currentItems && (
        currentItems
                  .filter((document:any) => document.documentBack)
                  .map((document:any, index:any) => 
                    (document.base64DocumentBack) ? (
                      <Grid key={index} size={4}>
                      <div className="user-card-image-wrap">
                      <label htmlFor={`docback-${index}`}>
                      <img
                        src={`data:image/png;base64,${document.base64DocumentBack}`}
                        className="face-image"
                      />
                      {/* <UserBiometryImage key={'back'} imagePath={document.documentBack} imageSide='back' /> */}
                      </label>
                    <FormControlLabel  value={index} control={<Radio
                    onChange={() => setBiometryUpdate({...biometryUpdate, documentBack: document.documentBack,  documentBackImage: document.base64DocumentBack})}
                    id={`docback-${index}`} />} label={<Typography variant="caption">{dateFormatter(document.documentDate)}</Typography>} />
          
          </div>
          </Grid>
                    ):(<></>)                 
                )
              )}
                
              
                  
                     
               
                  
                
                  
            
        
        
                  
                         
                  </Grid>

                  {fileUploaderStatus.uploading ? (
                  <Box className="upload-file-wrapper">
                  <Box className="upload-file-caption">
                  <Typography variant="caption">
                    {fileUploaderStatus.fileName}
                    {fileUploaderStatus.filePercent === 100 ? (
                      <span>upload completo!...</span>
                    ):(
<span>subindo... {Math.round(fileUploaderStatus.filePercent)}</span>
                    )}
                    
                  </Typography>
                  <SearchIcon />
                  </Box>
                  <LinearProgress color={ (fileUploaderStatus.filePercent === 100) ? 'success':'info'} variant="determinate" value={fileUploaderStatus.filePercent} />
                </Box>
    ):(
      <label htmlFor={`docback-uploader`} className="upload-field">
      <SearchIcon />
      <Typography variant="caption">
          Importar arquivo
        </Typography>
        <input className="controls-input"
          type="file"
          id="docback-uploader"
          accept="image/*"
          name='document_back'
          onChange={handleImageUpload}
        /> 
    </label> 
    )}

                  <Box className="user-approved-action-button">
                       <Button
                         onClick={handleBack}
                       >
                         Voltar
                       </Button>
        
                       <Button onClick={handleComplete}>
                        Próximo
                      </Button>
                     </Box>
                  </RadioGroup>
                  </FormControl>
        
        ) : (activeStep === 3) ? (
<div>
<Grid container spacing={2} className="modal-image-carrousel no-wrapper-frame">
 
                    <Grid size={4}>
                    <div className="user-card-image-wrap">
          <img
            src={`data:image/png;base64,${biometryUpdate.faceImage}`}
            className="document-image"
          />
        </div>
        </Grid>

        <Grid size={4}>
                    <div className="user-card-image-wrap">
          <img
            src={`data:image/png;base64,${biometryUpdate.documentFrontImage}`}
            className="document-image"
          />
        </div>
        </Grid>

        <Grid size={4}>
                    <div className="user-card-image-wrap">
          <img
            src={`data:image/png;base64,${biometryUpdate.documentBackImage}`}
            className="document-image"
          />
        </div>
        </Grid>
              
                  </Grid>
                  <Box className="user-approved-action-button">
                       <Button
                         onClick={handleBack}
                       >
                         Voltar
                       </Button>
        
                       <Button onClick={sendSelection}>
                                Concluir
                              </Button>
        
                     </Box>
</div>
        ):(<></>)}
      </div>
    </Box>

        </Box>
      </Modal>


      <Backdrop
  sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
  open={backgropLoader}
  onClick={handleBackgropLoaderClose}
>
  <CircularProgress color="inherit" />
</Backdrop>
    </Card>
  )
};

export default UserCard;